export const SERVICES = {
    'pageHead': "Services",
    'heading1': "KPIs - Definition, tracking and analysis",
    'subheading1': "We work with you to understand your business and define product and marketing KPIs. For regular tracking and monitoring the defined KPIs, we implement data platform infrastructure.",
    'heading2': "Custom data analysis",
    'subheading2_1': "Need a question answered? We are here to help",
    'subheading2_2': "Book time with us to talk through your needs and let us help you find the perfect solution!",

    'btn1': "Book Demo",

    'alt1': "Get all your data in one place and simplify analytics!",
}