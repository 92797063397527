import axios from 'axios';
import Config from './config';

axios.defaults.withCredentials = true;



const pingAPI = (path, type, body) => {
    console.log(Config.API_URL)
    const responsePromise = new Promise((resolve, reject) => {
        
    switch (type) {
        case 'GET':
            axios.get(Config.API_URL + path,
                ).then(response => {
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                });
                break;
        case 'POST':
            axios.post(Config.API_URL + path,
                body
                ).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
                break;
        default:
            reject("CQAPI Manager: invalid request type");
    }
    });

    return responsePromise;
}

const attemptTokenRefresh = () => {

    const tokenPromise = new Promise((resolve, reject) => {
        
        axios.get(Config.API_URL + '/auth/refresh-token').then(() => {
            resolve();
        }).catch(err => {
            reject(err);
        });
    });

    return tokenPromise;
}

const getCQResponse = (path, requestType, body) => {
    
    const APIPromise = new Promise((resolve, reject) => {
        pingAPI(path, requestType, body).then( response => {
            console.log('came here')
            resolve(response);
        }).catch( () => {
            console.log('in catch')
            attemptTokenRefresh().then(() => {
                console.log('will reattempt ping');
                pingAPI(path, requestType, body).then( response => {
                    resolve(response);
                }).catch();
            }).catch((err) => {
                reject(err);
            })
        });
    });
    return APIPromise;
}

export default getCQResponse;