import React, { Fragment } from 'react';
import sqlAccessImage from  './sql-access.svg'
import dataSanityImage from  './data-sanity.svg'
import styles from './ProductAnalytics.module.css'
import cx from 'classnames'

function ProductAnalytics() {
  return (
    <Fragment>
      <h1 id='title' className={styles.page_heading}>Product Analytics</h1>
      <div id='data-in-one' className={styles.feature_block}>
          <div className={styles.feature_box}>
            <h3 className={styles.feature_title}>
              Let nothing stand in your way
            </h3>
            <div className={styles.feature_description}>
              Get direct SQL access to your database and set up custom reports specific to the business need.<br />
              We also support in setting up reports and guiding you to write your own reports using this functionality.
            </div>
          </div>
          <div className={styles.feature_image}>
            <img src={sqlAccessImage} alt="Get all your data in one place and simplify analytics!" className={styles.feature_image_style}/>
          </div>
      </div>
      <div id ='automate-reports' className={cx(styles.feature_block, styles.analysis_block, styles.last_feature)}>
          <div className={ cx(styles.centralize_data_top_image, styles.feature_image) }>
            <img src={dataSanityImage} alt="Stay updated on latest changes in product and business trends!" className={styles.feature_image_style}/>
          </div>
          <div className={cx(styles.feature_box, styles.analysis_text)}>
            <h3 className={styles.feature_title}>
              Maintain data sanity for accurate analysis
            </h3>
            <div className={styles.feature_description}>
              Automate QA for your clickstream data to identify implementation errors early on and contain their impact on data sanity. <br/>
              We also provide managed services helping setup business specific automated QA checks.
            </div>
            <a href='https://calendly.com/cliqmetrics' target="_blank" rel="noreferrer">
              <button className={styles.demo_button}>Book Demo</button>
            </a>
          </div>
          {/* <div className={ cx(styles.centralize_data_bottom_image, styles.feature_image) }>
            <img src={dataSanityImage} alt="Get all your data in one place and simplify analytics!"/>
          </div> */}
      </div>
    </Fragment>
  );
}

export default ProductAnalytics;
