import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo.svg';
import { NavHashLink } from "react-router-hash-link";
import './Navbar.css';
// import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import getCQResponse from '../../utils/cq_api_manager';
import Config from '../../utils/config';

const Navbar = (props) => {

  const [ user, setUser] = useState({});

  const navigateToConsole = () => {
    window.location.assign(Config.BASE_URL);
  }
  
  const login = useGoogleLogin({
      onSuccess: async (code) => {
        window.location.assign(Config.BASE_URL + '/?gcode='+code["code"]);
      },
      flow: 'auth-code',
      onError: (error) => console.log('Login Failed:', error)
  });

  // log out function to log the user out of google and set the profile array to null
  // const logout = () => {
  //     googleLogout();
  //     props.setProfile({});
  // };

  useEffect(() => {
    getCQResponse('/init', 'GET').then(userProfile => {
      console.log('will set user to: ' + userProfile);
      setUser(userProfile);
      // console.log(user);
    }).catch();
  }, [setUser])

  return (
    <div className="nav-outer">
      <nav className="navbar">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <h1>Cliqmetrics</h1>
          </Link>
          <ul className="navbar-nav">
            <li className="product_header">
              <div className="nav-item product_header_text">Products</div>
              <ul className = 'products_dropdown'>
                <div className = 'product_dropdown_items'>
                  <a href='/data-platform' className = 'product_dropdown_item_text'>Data Platform</a>
                </div>
                <div className = 'product_dropdown_items'>
                  <a href='/product-analytics' className = 'product_dropdown_item_text'>Product Analytics</a>
                </div>
              </ul>
            </li>
            <li className='nav-item'>|</li>
            <li className="nav-item">
              <Link to="/services" className="nav-link">
                Services
              </Link>
            </li>
            <li className='nav-item'>|</li>
            <li className="nav-item">
              <Link to="/company" className="nav-link">
                Company
              </Link>
            </li>
          </ul>
          <div className='nav-icons-container'>
            <NavHashLink to='/#contact-us' smooth className='message_link'>
            <button type="submit" className='message_button'>Contact Us</button>
              {/* <img src={contatctUsIcon} className='nav-icons' alt="React Logo" /> */}
            </NavHashLink>
            {/* <GoogleLogin onSuccess={onSuccessHandler} onError={onErrorHandler} flow='auth-code' response_type='code'/> */}
            { user && Object.keys(user).length > 0 ? <button onClick={navigateToConsole}>Go to console</button> : <button onClick={() => login()}>Sign in with Google</button> }
          </div>
        </nav>
    </div>
  );
}

export default Navbar;
