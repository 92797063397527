import React, { Fragment } from 'react';
import styles from './MobileFooter.module.css';
// import MobileLogo from './MobileLogo.svg'
import ContactUsMobile from './ContactUsMobile.svg'
// import LoginMobile from './LoginMobile.svg'
import cx from 'classnames'
import { NavHashLink } from "react-router-hash-link";

class MobileFooter extends React.Component {

    render() {
            return (
                <Fragment>

                    <footer className={styles.mobile_footer}>
                        
                        <NavHashLink to="/#contact-us" smooth className={ cx(styles.footer_icons, styles.contact_icon) }>
                            <img src={ContactUsMobile} alt="Contact Us" />
                        </NavHashLink>
                        {/* <Link to="/" className={ cx(styles.footer_icons, styles.login_icon) }>
                            <img src={LoginMobile} alt="Login" />
                        </Link> */}
                </footer>
            </Fragment>
        );
    }
}

export default MobileFooter;