import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import BannerCarousel from './components/homepage_banner/BannerCarousel';
import TopBanner from './components/top_banner/Banner';
import ContactForm  from './components/contact_form/ContactForm';
import styles from './Home.module.css';
import cx from 'classnames'

class Home extends React.Component {

  state = {

  }

  render() {
    return (
      <Fragment>
        <TopBanner />
        <div className={styles.green_box}>
          <div className={styles.products_label_green_box}>Products</div>
        </div>
        <div className={styles.feature_row}>
          <div className={cx(styles.column, styles.first_product_feature)}>
            <div className ={styles.feature_heading} >Data Platform</div>
            <div className={styles.feature_description}>
            An integrated analytics solution for importing data from multiple sources into a central warehouse to power dashboards and automated reports.
            </div>
            <Link to="/data-platform#title" className={styles.button_wrapper}>
              <div className={styles.button_text}>
                Explore Platform
              </div>
            </Link>
          </div>
          <div className={styles.column}>
          <div className ={styles.feature_heading} >Product Analytics</div>
            <div className={styles.feature_description}>
            Analytics platform to help you better understand user behavior on your platform, what delights your customers and what gets in the way.
            </div>
            <Link to="/product-analytics#title" className={styles.button_wrapper}>
              <div className={styles.button_text}>
                Explore Analytics
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.green_box}>
          <div className={styles.products_label_green_box}>Services</div>
        </div>
        {/* <div className={styles.green_box}>
          <p>SERVICES</p>
        </div> */}
        <div className={styles.services}>
          <div className = {styles.feature_description}>We provide end-to-end data analytics services for small to medium scale products. Please reach out to us to understand how we can help. </div>
          <div className={cx(styles.button_wrapper, styles.servcies_cta)}>
            <Link to="/services#title" className={styles.button_text}>
              Explore Services
            </Link>
          </div>
        </div>
        <div id='contact-us' className={styles.contact_container}>
          <ContactForm />
        </div>
      </Fragment>
    );
  }

  // handleFormBlur(e){

  //   console.log("clicked outside");
  //   var textField = document.getElementById(e.target.id);
  //   // console.log(target);
  //   if (textField.value == null) {
  //     textField.value = '';
  //   }
  // }

  // clearTextFieldValue(target) {
  //   var textField = document.getElementById("name");
  //   console.log(target);
  //   if (textField.value == textField.defaultValue) {
  //     textField.value = '';
  //   }
  // }
}


export default Home;
