import React, { Fragment } from 'react';
import aboutUsImage from  './about-us.svg'
import styles from './Company.module.css'

function Services() {
  return (
    <Fragment>
      <h1 id='title' className={styles.page_heading}>About Us</h1>
      <div className={styles.feature_box}>
        <div className={styles.feature_description}>
          We are a team of product analysts and managers who understand what it takes to build a product that delights its customers. Our team brings in experience across industries, and we have strive to remove hurdles for, and achieve optimal data utilization.
        </div>
        <div className={styles.feature_image}>
          <img src={aboutUsImage} alt="Get all your data in one place and simplify analytics!"/>
        </div>
        <div className={styles.feature_description}>
        The key to the hearts of your consumers is user experience, and our goal is for you to have the right insights to enhance that experience. Our partners are on their journey to something big and we are passionate about making it bigger.
        </div>
      </div>
    </Fragment>
  );
}

export default Services;
