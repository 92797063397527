import React, { useCallback, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar/NavBar';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import MobileMenu from './components/MobileNavbar/MobileMenu/MobileMenu';
import Home from './pages/homepage/Home';
import DataPlatform from './pages/data_platform/DataPlatform';
import ProductAnalytics from './pages/product_analytics/ProductAnalytics';
import Services from './pages/services/Services';
import Company from './pages/company/Company';
import Footer from './components/Footer/Footer'
import MobileFooter from './components/MobileFooter/MobileFooter'
import './App.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Config from './utils/config';

// import axios from 'axios';
// import Cookies from 'universal-cookie';
// import {decode as base64_decode, encode as base64_encode} from 'base-64';

const App = () => {

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  // const [ user, setUser ] = useState({});
  // const [ profile, setProfile ] = useState({});

  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu(!showMobileMenu);
  }, [showMobileMenu]);
  
  // const getGoogleProfile = (tkn) => {
  //   const access_token = Object.keys(user).length > 0 ? user.access_token : tkn; 
  //   if ((Object.keys(user)).length > 0) {
  //     axios
  //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
  //             headers: {
  //                 Authorization: `Bearer ${user.access_token}`,
  //                 Accept: 'application/json'
  //             }
  //         })
  //         .then((res) => {
  //             const cookies = new Cookies();
  //             let encodedUser = base64_encode(JSON.stringify(user));
  //             cookies.set('gtkn', encodedUser, { path: '/' });
  //             setProfile(res.data);
  //         })
  //         .catch((err) => console.log(err));
  //   }
  // }  

  // useEffect(
  //   () => {
  //     console.log(profile);
  //   },
  //   [profile]
  // );

  return (
    <GoogleOAuthProvider clientId = { Config.GOOGLE_AUTH_ID } >
      <div className='page-container'>
        <NavBar />
        <MobileNavbar toggleMobileMenu={toggleMobileMenu} />
        <MobileMenu showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />
        <div className='outer-container'>
          <div className='container'>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/data-platform"  element={<DataPlatform />} />
              <Route exact path="/product-analytics" element={<ProductAnalytics />} />
              <Route exact path="/services" element={<Services />} />
              <Route exact path="/company" element={<Company />} />
            </Routes>
          </div>
        </div>
        <Footer />
        <MobileFooter />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
