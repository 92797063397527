import React, { Fragment } from 'react';
import dataNodeImage from  './data-node.svg'
import autoReportsImage from  './auto-reports.svg'
import styles from './DataPlatform.module.css'
import cx from 'classnames'

function DataPlatform() {
  return (
    <Fragment>
      <h1 id='title' className={styles.page_heading}>Data Platform</h1>
      <div id='data-in-one' className={styles.feature_block}>
          <div className={styles.feature_box}>
            <h3 className={styles.feature_title}>
              Get all your data in one place
            </h3>
            <div className={styles.feature_description}>
              Simplify reporting by importing data from multiple sources to a central data warehouse.<br/>
              We support data pulls from any platform that allows API access.
            </div>
            {/* <a href='https://calendly.com/cliqmetrics' target="_blank" rel="noreferrer">
              <button className={styles.demo_button}>Book Demo</button>
            </a> */}
          </div>
          <div className={styles.feature_image}>
            <img src={dataNodeImage} alt="Get all your data in one place and simplify analytics!" className={styles.feature_image_style}/>
          </div>
      </div>
      <div id ='automate-reports' className={cx(styles.feature_block, styles.automate_block, styles.last_feature)}>
          <div className={ cx(styles.auto_reports_top_image, styles.feature_image) }>
            <img src={autoReportsImage} alt="Get all your data in one place and simplify analytics!" className={styles.feature_image_style}/>
          </div>
          <div className={cx(styles.feature_box, styles.communications_text)}>
            <h3 className={styles.feature_title}>
              Set up reports and automate communications
            </h3>
            <div className={styles.feature_description}>
              Stay on top of changes in key business metrics with well custom dashboards and automated email reports to your team.<br/>
              We provide the initial setup required and then continued support as per the terms of our engagement.
            </div>
            <a href='https://calendly.com/cliqmetrics' target="_blank" rel="noreferrer">
              <button className={styles.demo_button}>Book Demo</button>
            </a>
          </div>
          {/* <div className={ cx(styles.auto_reports_bottom_image, styles.feature_image) }>
            <img src={autoReportsImage} alt="Get all your data in one place and simplify analytics!"/>
          </div> */}
      </div>
    </Fragment>
  );
}

export default DataPlatform;
