import React from 'react';
import styles from './MobileNavbar.module.css'
import HamBurger from './HamburgerIcon.svg'
// import cx from 'classnames'
import { Link } from 'react-router-dom';
import MobileLogo from './MobileLogo.svg'

class MobileNavbar extends React.Component {
    
    state= {}

    constructor(props) {

        super(props);
        this.toggleMobileMenu = props.toggleMobileMenu;

    }

    render() {
       return (
        
        <div className={styles.transparent_box}>
            <Link to="/" className={styles.logo}>
                <img src={MobileLogo} alt="Company Logo" />
            </Link>
            <img src={HamBurger} alt="HamBurger Menu" className={styles.hamburger} onClick={this.toggleMobileMenu}/>
        </div>
       )
    }
}

export default MobileNavbar;