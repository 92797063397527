import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './MobileMenu.module.css'
import BackArrow from './BackArrow.svg'
import CQLogo from './CQLogo.svg'
import DownArrow from './DownArrow.svg'
import cx from 'classnames'

const MobileMenu = ({ showMobileMenu, toggleMobileMenu }) => {

    const [showProductsMenu, setShowProductsMenu] = useState(false);
    const wrapperRef = useRef();

    const toggleProductsMenu = useCallback(() => {
        setShowProductsMenu(!showProductsMenu);
    }, [showProductsMenu]);

    const handleClickOutside = useCallback((event) => {
        // console.log("here")
        // console.log(showMobileMenu)
        if (showMobileMenu && wrapperRef && !wrapperRef.current.contains(event.target)) {
            setShowProductsMenu(false)
            // console.log("this was called")
            toggleMobileMenu()
        }
    }, [showMobileMenu, toggleMobileMenu]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        // eslint-disable-next-line
    }, []);

    // console.log("outside: " + showMobileMenu)
    return (
        <div ref={wrapperRef} className={showMobileMenu ? styles.mobile_menu : cx(styles.mobile_menu, styles.hide_element)}>
            <div className={styles.close_container}>
                <img src={BackArrow} alt="Back Arrow" className={styles.close_button} onClick={toggleMobileMenu} />
            </div>
            <div className={styles.menu_container}>
                <div className={styles.central_container}>
                    <a href='/' className = {styles.home_link}>
                        <div className={styles.cq_brand}>
                            <img src={CQLogo} alt="CQ Logo" className={styles.cq_logo} />
                            <h3 className={styles.cq_company_name}>Cliqmetrics</h3>
                        </div>
                    </a>
                    <div className={styles.menu_items}>
                        <div className={styles.product}>
                            <div className={styles.title_container} onClick={toggleProductsMenu}>
                                <div className={styles.products_menu_title}>Products</div>
                                <img src={DownArrow} alt="down arrow" className={styles.down_arrow} />
                            </div>
                            <div className={showProductsMenu ? styles.product_menu : cx(styles.product_menu, styles.hide_element)}>
                                <a className={styles.product_menu_item} href='/data-platform'>Data Platform</a>
                                <a className={styles.product_menu_item} href='/product-analytics'>Product Analytics</a>
                            </div>
                        </div>
                        <a className={styles.menu_item} href='/services' >
                            Services
                        </a>
                        <a className={styles.menu_item} href='/company'>
                            Company
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MobileMenu