import React from 'react';
import styles from './ContactForm.module.css';
import image from './image.jpg';
import cx from 'classnames';

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            company: '',
            phone: '',
            message: '',
            showThanks: false
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
      }
    
      handleSubmit(event) {
        event.preventDefault();
        const url = 'https://docs.google.com/forms/d/e/1FAIpQLSdX5FwCpTTDPf4-FlKMZkoa5LBzrrv2Phq4Eiwn_Nf5nwwa7A/formResponse?entry.1167413529=' + this.state.name + '&entry.600697047=' + this.state.email + '&entry.638959840=' + this.state.company + '&entry.25621956=' + this.state.phone + '&entry.1173988266=' + this.state.message + '&submit=Submit'
        fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
             .then((response) => response.json())
             .then((data) => {
                
                // Handle data
             })
             .catch((err) => {
                // console.log("Form submitted");
                this.setState({
                    showThanks: true
                })
             });
      }
      

    render() {
        return (
            <div className={styles.contact_form}>
                <img src={image} alt="Contact us" className={styles.contact_image} />
                {/* <div className={styles.form_container}> */}
                    <form onSubmit={this.handleSubmit} className={!this.state.showThanks ? styles.form_container : cx(styles.form_container, styles.hide_element)}>
                        <h3 className={cx(styles.form_heading, styles.field_outer)}>Message Us</h3>
                        <div className={styles.field_outer}>
                            <input type="text" id="name" className={styles.input_field} name="name" required placeholder = "Name" value={this.state.name} onChange={this.handleChange}/>
                        </div>
                        <div className={styles.field_outer}>
                            <input type="email" id="email" className={styles.input_field}  name="email" required placeholder = "Email" value={this.state.email} onChange={this.handleChange}/>
                        </div>
                        <div className={styles.field_outer}>
                            <input type="text" id="company" className={styles.input_field}  name="company" required placeholder = "Company Name" value={this.state.company} onChange={this.handleChange}/>
                        </div>
                        <div className={styles.field_outer}>
                            <input type="number" id="phone" className={cx(styles.form_phone, styles.input_field)} name="phone" required placeholder = "Phone Number" value={this.state.phone} onChange={this.handleChange} pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"/>
                        </div>
                        {/* </form></span> */}
                        {/* <input id="phone" type="tel" name="phone" /> */}
                        <div className={styles.field_outer}>
                            <textarea id="message" className={cx(styles.input_field, styles.message_field)}  name="message" required placeholder = "Message" value={this.state.message} onChange={this.handleChange}/>
                        </div>
                        <button type="submit" className={styles.button}>Submit</button>
                    </form>
                    <div className={this.state.showThanks ? styles.thankyou_message : cx(styles.thankyou_message, styles.hide_element)}>
                        Thank you for your message!<br/>Our team will reach out to you shortly :&#41;
                    </div>
                </div>
            // </div>
        );
    }

}

export default ContactForm