import React, { Fragment } from 'react';
import './Footer.css';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom';
import { NavHashLink } from "react-router-hash-link";

class Footer extends React.Component {

    render() {
            return (
                <Fragment>

                    <footer className='footer'>
                    <div className="footer-container">
                        {/* <div class="footer-border"> */}
                            <div className="logo footer-columns">
                                <Link to="/" className="navbar-brand">
                                    <img src={logo} alt="Company Logo" />
                                </Link>
                            </div>
                            <div className="footer-columns">
                                <h3>PRODUCTS</h3>
                                <ul>
                                    <li><a href="/data-platform">Data Platform</a></li>
                                    <li><a href="/product-analytics">Product Analytics</a></li>
                                </ul>
                            </div>
                            <div className="footer-columns">
                                <h3>SERVICES</h3>
                                <ul>
                                <li><a href="/services">End to end Analytics</a></li>
                                </ul>
                            </div>
                            <div className="footer-columns">
                                <h3>COMPANY</h3>
                                <ul>
                                <li><a href="/company">About Us</a></li>
                                </ul>
                            </div>
                            {/* <div className="footer-columns">
                                <h3>FOLLOW US</h3>
                                <ul>
                                    <li><a href="https://ww.facebook.com/cliqmetrics">Facebook</a></li>
                                    <li><a href="https://www.linkedin.com/companies/cliqmetrics">LinkedIn</a></li>
                                </ul>
                            </div> */}
                            <div className="footer-columns">
                                <h3>COMMUNITY</h3>
                                <ul>
                                    <li><NavHashLink to='/#contact-us' smooth>Contact Us</NavHashLink></li>
                                </ul>
                            </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;