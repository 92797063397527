const DEV = {
    BASE_URL: 'https://localhost:4000',
    API_URL: 'https://localhost:5000',
    GOOGLE_AUTH_ID: '69802119849-apbffu4ilhu3b9nogukhlg0m71mtaspi.apps.googleusercontent.com'
} 

const STAGING = {
    a: 10
}

const PRODUCTION = {
    BASE_URL: 'https://app.cliqmetrics.com',
    API_URL: 'https://api.cliqmetrics.com',
    GOOGLE_AUTH_ID: '69802119849-nqc1mtbgar4ljd8fl45293lv4fv5kuuq.apps.googleusercontent.com'
}

const Config = process.env.NODE_ENV === 'production' ? PRODUCTION : process.env.NODE_ENV === 'STAG' ? STAGING : DEV;

export default Config;