import React from 'react';
// import './ImageBanner.css'; // Import the CSS file for styling
import banner1 from './banner.svg';
import styles from './Banner.module.css';
import MobileBanner from './mobile_banner.svg';
import cx from 'classnames'

class TopBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTextIndex: 0,
      texts  : ["Simplify Reporting", "Eliminate Data Barriers", "Understand User Journeys"]
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        currentTextIndex: (prevState.currentTextIndex + 1) % this.state.texts.length,
      }));
    }, 3000); // Change the duration (in milliseconds) to adjust the time between text changes
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const texts = this.state.texts;
    const { currentTextIndex } = this.state;

    return (
      <div className={styles.image_banner_container}>
        <img
          src={banner1}
          alt="Redefine data utilization"
          className={cx(styles.image_banner_image, styles.desktop_banner)}
        />
        <img
          src={MobileBanner}
          alt="Redefine data utilization"
          className={cx(styles.image_banner_image, styles.mobile_banner)}
        />
        {/* <div className={styles.image_banner_text_container}> */}
          {/* <div className={styles.image_banner_text}>{texts[0]}</div> */}
          {texts.map((text, index) => (
            <div
              key={index}
              // className={styles.image_banner_text}
              className={cx(styles.image_banner_text,
                currentTextIndex === index ? styles.fade_in : styles.fade_out)
              }
            >
              {text}
            </div>
          ))}
        {/* </div> */}
      </div>
    );
  }
}

export default TopBanner;
