import React, { Fragment } from 'react';
import dataAnalysisImage from './data-analysis.svg'
import dataDefinitionImage from './data-definition.svg'
import styles from './Services.module.css'
import cx from 'classnames'
import { SERVICES as CONSTANTS } from '../../translate'


const Services = () => (
  <>
    <h1 id='title' className={styles.page_heading}>{CONSTANTS.pageHead}</h1>
    <div id='data-in-one' className={styles.feature_block}>
      <div className={styles.feature_box}>
        <h3 className={styles.feature_title}>
          {CONSTANTS.heading1}
        </h3>
        <div className={styles.feature_description}>
          {CONSTANTS.subheading1}
        </div>
      </div>
      <div className={styles.feature_image}>
        <img src={dataDefinitionImage} alt={CONSTANTS.alt1} />
      </div>
    </div>
    <div id='automate-reports' className={cx(styles.feature_block, styles.custom_analysis_block, styles.last_feature)}>
      <div className={cx(styles.simplify_analytics_top_image, styles.feature_image)}>
        <img src={dataAnalysisImage} alt={CONSTANTS.alt1} />
      </div>
      <div className={cx(styles.feature_box, styles.custom_analysis_text)}>
        <h3 className={styles.feature_title}>
          {CONSTANTS.heading2}
        </h3>
        <div className={styles.feature_description}>
          {CONSTANTS.subheading2_1}<br />
          {CONSTANTS.subheading2_2}
        </div>
        <a href='https://calendly.com/cliqmetrics' target="_blank" rel="noreferrer">
          <button className={styles.demo_button}>{CONSTANTS.btn1}</button>
        </a>
      </div>
      {/* <div className={cx(styles.simplify_analytics_bottom_image, styles.feature_image)}>
        <img src={dataAnalysisImage} alt={CONSTANTS.alt1} />
      </div> */}
    </div>
  </>
);

export default Services;
